"use strict";

(function () {
  "use strict";

  config.$inject = ["$stateProvider"];
  angular.module("schedules").config(config);

  function config($stateProvider) {
    $stateProvider.state("schedules", {
      url: "/schedules",
      templateUrl: "app/pages/schedules/schedules.html",
      controller: "SchedulesCtrl",
      controllerAs: "vm",
      resolve: {
        ready: function ready($q, $rootScope) {
          var deferred = $q.defer();
          if ($rootScope.$user) {
            deferred.resolve("");
          } else {
            $rootScope.$watch(function () {
              return $rootScope.$user;
            }, function () {
              if ($rootScope.$user) {
                deferred.resolve("");
              }
            }, true);
          }
          return deferred.promise;
        }
      }
    }).state("schedule", {
      url: "/schedule/:id",
      templateUrl: "app/pages/schedules/schedule.html",
      controller: "ScheduleCtrl",
      controllerAs: "vm",
      resolve: {
        params: function params() {
          return {};
        }
      }
    });
  }
})();